import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { Company } from '../../types/Company'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import { Person } from '../../types/Person'
import theme from '../../assets/theme'
import { CompanyType } from '../../types/enums/CompanyType'
import { FlowType } from '../../types/enums/FlowType'
import CompanyStructureOld from './components/CompanyStructureOld'
import LimitedCompanyForm, { LimitedCompanyFormParams } from './components/LimitedCompanyForm'
import SoleTraderForm, { SoleTraderFormParams } from './components/soleTrader/SoleTraderForm'
import { VirtualPage } from '../../types/enums/VirtualPage'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'
import { CompanyTypeContext } from './CompanyTypeContext'
import CompanyInformationPageWrapper from './components/CompanyInformationPageWrapper'
import { useFeatureFlags } from '../../custom-hooks/useFeatureFlags'
import { IdentifyLimitedCompaniesByEmailOrPhone } from '../../unleash/unleashConfig'
import PrePopulationForm from './components/prePopulation/PrePopulationForm'
import CompanyStructure from './components/CompanyStructure'
import { useInitiateSoleTraderOnboardingJourney } from '../../custom-hooks/useInitiateSoleTraderOnboardingJourney'
import SoleTraderDetailsForm from './components/soleTrader/SoleTraderDetailsForm'
import { Navigate } from 'react-router-dom'

export type CompanyInformationForm = {
  companyType?: CompanyType
  requester?: Person
  company?: Company
  soleTraderEmail?: string
}

type FormComponentsType = {
  limitedcompany: ({ onboardingJourney }: LimitedCompanyFormParams) => JSX.Element
  soletrader: ({ onboardingJourney }: SoleTraderFormParams) => JSX.Element
}
const flowTypeMap = {
  [CompanyType.LimitedCompany]: FlowType.LimitedCompany,
  [CompanyType.GovernmentEntity]: FlowType.LimitedCompany,
  [CompanyType.SoleTrader]: FlowType.SoleTrader
}

const companyTypeMap = {
  [FlowType.LimitedCompany.toLowerCase()]: CompanyType.LimitedCompany,
  [FlowType.SoleTrader.toLowerCase()]: CompanyType.SoleTrader
}

const formComponentsOld = {
  limitedcompany: LimitedCompanyForm,
  soletrader: SoleTraderForm
} as FormComponentsType

const formComponents = {
  limitedcompany: LimitedCompanyForm,
  soletrader: SoleTraderDetailsForm
} as FormComponentsType

const CompanyInformationPage = (data: OnboardingJourney) => {
  const trackPageView = useVirtualPageView()
  const trackEvent = useTrackEvent()
  const [flowType, setFlowType] = useState<FlowType | undefined>(data.flowType)
  const isEngagementThresholdMet = useRef<boolean>(false)
  const featureFlags = useFeatureFlags()
  const isIdentifyLimitedCompaniesByEmailOrPhoneEnabled = featureFlags[IdentifyLimitedCompaniesByEmailOrPhone] ?? false
  const isBlankOnboardingJourney = data.company === null && data.requester === null && data.director === null
  const { mutate: initiateSoleTraderFlow, isLoading: isMutationLoading } = useInitiateSoleTraderOnboardingJourney({})
  const preselectedCompanyType = data.flowType ? companyTypeMap[data.flowType.toLocaleLowerCase()] : undefined
  const [companyType, setCompanyType] = useState<string | undefined>(preselectedCompanyType)
  let companyStructureShouldBeDisplayed = isIdentifyLimitedCompaniesByEmailOrPhoneEnabled
    ? isMutationLoading || !companyType
    : !data.flowType ||
      (data.flowType.toLowerCase() === FlowType.LimitedCompany.toLowerCase() && !data.company?.kriyaCompanyIdentifier)

  useEffect(() => {
    if (!isIdentifyLimitedCompaniesByEmailOrPhoneEnabled) return
    if (
      data.company?.kriyaCompanyIdentifier ||
      data.flowType?.toLocaleLowerCase() === FlowType.SoleTrader.toLocaleLowerCase()
    ) {
      setCompanyType(companyTypeMap[data.flowType!.toLocaleLowerCase()])
    } else {
      setCompanyType(undefined)
    }
  }, [data, isIdentifyLimitedCompaniesByEmailOrPhoneEnabled])

  useEffect(() => {
    companyStructureShouldBeDisplayed = isMutationLoading || !companyType
  }, [isMutationLoading, companyType])

  useEffect(() => {
    trackPageView(VirtualPage.COMPANY_SELECTION)

    if (!isEngagementThresholdMet.current) {
      const timer = setTimeout(() => {
        isEngagementThresholdMet.current = true
        trackEvent(EventTypes.CompanyInformation.ENGAGEMENT_MET)
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [])

  function setFlowTypeFromCompanyType(value: string) {
    setCompanyType(value)
    setFlowType(flowTypeMap[value as CompanyType])
    if (isIdentifyLimitedCompaniesByEmailOrPhoneEnabled && (value as CompanyType) === CompanyType.SoleTrader) {
      trackEvent(EventTypes.CompanyInformation.CLICK_COMPANY_SELECTION_CONTINUE, {
        companyType: CompanyType.SoleTrader,
        identificationType: data.requester.email ?? data.requester.phoneNumber ?? ''
      })

      initiateSoleTraderFlow({ directorEmail: data.requester.email, directorPhoneNumber: data.requester.phoneNumber })
    }
  }

  const flowTypeKey = flowType?.toLowerCase() as keyof FormComponentsType
  const FormToRender = isIdentifyLimitedCompaniesByEmailOrPhoneEnabled
    ? formComponents[flowTypeKey]
    : formComponentsOld[flowTypeKey]

  if (isIdentifyLimitedCompaniesByEmailOrPhoneEnabled) {
    if (
      data.flowType?.toLowerCase() === FlowType.SoleTrader.toLowerCase() &&
      data.requester !== null &&
      data.director === null
    ) {
      return <Navigate to={'/error'} replace />
    }

    if (isBlankOnboardingJourney) {
      return (
        <CompanyInformationPageWrapper data={data}>
          <PrePopulationForm data={data} />
        </CompanyInformationPageWrapper>
      )
    }
    return (
      <CompanyInformationPageWrapper data={data} displayDefaultTitle={companyStructureShouldBeDisplayed}>
        <CompanyTypeContext.Provider
          value={{
            companyType,
            setCompanyType: setFlowTypeFromCompanyType,
            settingCompanyTypeInProgress: isMutationLoading
          }}
        >
          {companyStructureShouldBeDisplayed && (
            <Box
              sx={{
                marginTop: theme.spacing(32),
                marginBottom: !companyType ? theme.spacing(22) : theme.spacing(12)
              }}
            >
              <CompanyStructure
                merchantName={data.merchant.name}
                redirectUrl={data.abandonedUrl}
                flowType={data.flowType}
              />
            </Box>
          )}

          {!isMutationLoading && companyType && FormToRender && <FormToRender onboardingJourney={data} />}
        </CompanyTypeContext.Provider>
      </CompanyInformationPageWrapper>
    )
  }

  return (
    <CompanyInformationPageWrapper data={data}>
      <CompanyTypeContext.Provider
        value={{ companyType, setCompanyType: setFlowTypeFromCompanyType, settingCompanyTypeInProgress: false }}
      >
        {companyStructureShouldBeDisplayed && (
          <Box
            sx={{
              marginTop: theme.spacing(32),
              marginBottom: !companyType ? theme.spacing(22) : theme.spacing(12)
            }}
          >
            <CompanyStructureOld
              merchantName={data.merchant.name}
              redirectUrl={data.abandonedUrl}
              flowType={data.flowType}
            />
          </Box>
        )}

        {FormToRender && <FormToRender onboardingJourney={data} />}
      </CompanyTypeContext.Provider>
    </CompanyInformationPageWrapper>
  )
}

export default CompanyInformationPage
