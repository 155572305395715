import { UserIdentifiers } from '../types/UserIdentifiers'

const availableStorage = typeof window.localStorage !== undefined

export const userEmailIdentifier = 'payments_userEmailIdentifier'
export const userPhoneNumberIdentifier = 'payments_userPhoneNumberIdentifier'

export const RetrieveUserData = (key: string): string | null => {
  return availableStorage ? window.localStorage.getItem(key) : null
}

export const DeleteLocalStorageItem = (key: string) => {
  if (availableStorage && key !== undefined) window.localStorage.removeItem(key)
}

export const GetUserIdentifiers = (): UserIdentifiers | undefined => {
  const email = RetrieveUserData(userEmailIdentifier) ?? undefined
  const phoneNumber = RetrieveUserData(userPhoneNumberIdentifier) ?? undefined

  return { email: email, phoneNumber: phoneNumber }
}

export const SetUserIdentifiers = (userIdentifiers: UserIdentifiers) => {
  DeleteLocalStorageItem(userEmailIdentifier)
  DeleteLocalStorageItem(userPhoneNumberIdentifier)
  if (userIdentifiers.email !== undefined) window.localStorage.setItem(userEmailIdentifier, userIdentifiers.email)
  if (userIdentifiers.phoneNumber !== undefined)
    window.localStorage.setItem(userPhoneNumberIdentifier, userIdentifiers.phoneNumber)
}
