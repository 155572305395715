import React from 'react'
import { OnboardingJourney } from '../../../types/OnboardingJourney'
import Logo from '../../../components/Logo'
import Box from '@mui/material/Box'
import { Content, ContentGroup } from '../../../assets/styles/ContentStyles'
import Typography from '@mui/material/Typography'
import theme from '../../../assets/theme'
import Faq from '../../../components/faq/Faq'
import styled from '@emotion/styled'
import Link from '@mui/material/Link'
import { useFeatureFlags } from '../../../custom-hooks/useFeatureFlags'
import { IdentifyLimitedCompaniesByEmailOrPhone } from '../../../unleash/unleashConfig'
import { FlowType } from '../../../types/enums/FlowType'

type CompanyInformationPageWrapperProps = {
  data: OnboardingJourney
  displayDefaultTitle?: boolean
  children?: React.ReactNode
}

const StyledLink = styled(Link)`
  &.MuiLink-root {
    color: ${theme.palette.common.textGrey900};
  }
`

const CompanyInformationPageWrapper = ({
  data,
  displayDefaultTitle = true,
  children
}: CompanyInformationPageWrapperProps) => {
  const featureFlags = useFeatureFlags()
  const isIdentifyLimitedCompaniesByEmailOrPhoneEnabled = featureFlags[IdentifyLimitedCompaniesByEmailOrPhone] ?? false
  let titleToDisplay = 'Welcome to Kriya'
  let displayContent = true
  if (isIdentifyLimitedCompaniesByEmailOrPhoneEnabled) {
    if (!displayDefaultTitle) titleToDisplay = 'Unlock your spending limit'
    displayContent = data.company !== null || data.requester !== null || data.director !== null
  }

  return (
    <>
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <Box
        sx={{
          marginTop: { xs: theme.spacing(24), sm: theme.spacing(32) }
        }}
      >
        <Content
          sx={{
            marginBottom: theme.spacing(10)
          }}
        >
          <Typography variant="h2" data-cy="title">
            {titleToDisplay}
          </Typography>

          {displayContent && (
            <ContentGroup style={{ rowGap: theme.spacing(8) }}>
              <Typography variant="body2" data-cy="content">
                To unlock your spending limit, we need some more information about your business.
              </Typography>
            </ContentGroup>
          )}
        </Content>

        {children}

        <Box>
          <Box style={{ marginTop: theme.spacing(20), marginBottom: theme.spacing(31) }}>
            <Typography variant="helperText2" sx={{ color: theme.palette.common.textGrey900 }}>
              We're committed to handling your data with the highest standards of privacy. For more details, please
              review our{' '}
              <StyledLink
                href="https://www.kriya.co/privacy-policy"
                data-cy="privacy-policy-link"
                aria-label="Review Privacy Policy"
                target="_blank"
              >
                Privacy Policy
              </StyledLink>
            </Typography>
          </Box>

          <Faq marketplaceName={data.merchant.name} />
        </Box>
      </Box>
    </>
  )
}

export default CompanyInformationPageWrapper
