import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import theme from '../../../../assets/theme'
import { ContentGroup } from '../../../../assets/styles/ContentStyles'
import { UserIdentifierType } from '../../../../types/enums/UserIdentifierType'

export type IdentifierSelectionProps = {
  identifierType?: UserIdentifierType
  onIdentifierTypeChange: (value: UserIdentifierType) => void
}

const IdentifierSelection = ({
  identifierType: identifierType,
  onIdentifierTypeChange: onIdentifierTypeChange
}: IdentifierSelectionProps) => {
  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: UserIdentifierType) => {
    onIdentifierTypeChange(value)
  }
  return (
    <>
      <Box sx={{ marginBottom: theme.spacing(10) }}>
        <Typography data-cy="header" variant="caption">
          How do you want to identify yourself?
        </Typography>
        <ContentGroup style={{ rowGap: theme.spacing(8), marginTop: theme.spacing(8) }}>
          <Typography variant="body2" data-cy="content">
            If you have used Kriya PayLater before, please enter the same email or number you registered before.
          </Typography>
        </ContentGroup>
      </Box>
      <Box>
        <RadioGroup
          id="identifier-selection-radio-buttons-group"
          aria-labelledby="identifier-selection-radio-buttons-group"
          name="identifier-selection-radio-buttons-group"
          value={identifierType}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, value: string) => onChange(_, value as UserIdentifierType)}
        >
          <FormControlLabel value={UserIdentifierType.Email} control={<Radio id="email-radio" />} label="Email" />
          <FormControlLabel
            value={UserIdentifierType.PhoneNumber}
            control={<Radio id="phoneNumber-radio" />}
            label="Mobile number"
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default IdentifierSelection
