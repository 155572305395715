import { IVariant, useFlags } from '@unleash/proxy-client-react' // Assuming useFlags comes from here

interface IFeatureFlag {
  name: string
  enabled: boolean
}

export const useFeatureFlags = () => {
  const flagToggles = useFlags()
  return flagToggles.reduce((acc: { [key: string]: boolean }, flag: IFeatureFlag) => {
    acc[flag.name] = flag.enabled
    return acc
  }, {})
}

export const isControlGroup = (variant: Partial<IVariant>): boolean => {
  return variant.name?.toLowerCase() === 'control'
}

export const isVariantActive = (variant: Partial<IVariant>): boolean => {
  return variant.feature_enabled === true && variant.enabled === true
}
