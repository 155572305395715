import { apiClient } from '../api/apiClient'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UserIdentifiers } from '../types/UserIdentifiers'
import { fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'

export const prePopulateOnboardingJourneyInfo = async (
  userIdentifiers: UserIdentifiers,
  onboardingJourneyId: string
): Promise<number> => {
  const request = { requester: { ...userIdentifiers } }
  const response = await apiClient.post(`/${onboardingJourneyId}/prepopulate`, request)
  return response.status
}

export const usePrePopulateOnboardingJourneyInfo = ({
  onboardingJourneyId,
  onSuccess,
  onError
}: {
  onboardingJourneyId: string
  onSuccess?: () => void
  onError?: () => void
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const prePopulateOnboardingJourneyMutation = useMutation(
    async (identifiers: UserIdentifiers): Promise<number> => {
      const data = await prePopulateOnboardingJourneyInfo(identifiers, onboardingJourneyId)
      return data
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(fetchOnboardingJourneyKey)

        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate('/error')
        }
      }
    }
  )

  return prePopulateOnboardingJourneyMutation
}
