import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import theme from '../../../../assets/theme'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'

export interface AgreementDialogProps {
  openDialog: boolean
  onClose: () => void
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 752px;
    ${theme.breakpoints.down('sm')} {
      min-width: 347px;
    }
  }
  & .MuiDialogTitle-root {
    padding: ${theme.spacing(8, 32)};
    border-bottom: 1px solid ${theme.palette.common.backgroundGrey100};
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(11, 12)};
    }
  }
  & .MuiDialogContent-root {
    padding: ${theme.spacing(8, 32)} !important;
    border-bottom: 1px solid ${theme.palette.common.backgroundGrey100};
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(11, 12)} !important;
    }
  }
  & .MuiDialogActions-root {
    padding: ${theme.spacing(16, 32)};
    justify-content: left;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(11, 12)};
    }
  }
`

function InlinedAgreementDialog({ openDialog, onClose }: AgreementDialogProps) {
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (openDialog) {
      handleDialogOpen()
    }
  }, [openDialog])

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <>
      <StyledDialog
        onClose={handleDialogClose}
        open={open}
        role="dialog"
        aria-labelledby="agreement-dialog-title"
        aria-describedby="agreement-content"
      >
        <DialogTitle id="agreement-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h1">Declaration of Sole Trader for Kriya Agreement</Typography>
            <div>
              <IconButton id="agreement-close-button" onClick={handleDialogClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent id="agreement-content" sx={{ typography: 'body2' }}>
          <p>I represent, confirm and make the following declaration to Kriya as follows: </p>
          <p>I am entering into this Agreement wholly for the purposes of a business carried on by me;</p>
          <p>
            I understand that I will not have the benefit of the protection and remedies that would be available to me
            under the Financial Services and Markets Act 2000 or under the Consumer Credit Act 1974 if the PayLater
            Agreement were a regulated agreement under those Acts;
          </p>
          <p>
            I understand that this declaration does not affect the powers of the court to make an order under section
            140B of the Consumer Credit Act 1974 in relation to a credit agreement where it determines that the
            relationship between the lender and the borrower is unfair to the borrower; and
          </p>
          <p>
            I am aware that, if I am in any doubts as to the consequences of the agreement not being regulated by the
            Financial Services and Markets Act 2000 or the Consumer Credit Act 1974, then I should seek independent
            legal advice.
          </p>
          <p>
            Finally, I confirm that the information I have provided is truthful and accurate to the best of my
            knowledge. I understand that providing inaccurate or misleading information may result in the rejection of
            my application / request and could potentially lead to further legal claims.
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant="dialog" onClick={handleDialogClose} id="agreement-dialog-confirm">
            I acknowledge and confirm
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  )
}

export default InlinedAgreementDialog
