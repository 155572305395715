export const EventTypes = {
  Eligibility: {
    RISK_DECISION_RETRY: 'effect_onboarding_riskDecision_retryDisplayed',
    RISK_DECISION_DELAYED: 'effect_onboarding_riskDecision_retryDelayed'
  },
  FAQ: {
    CLICK_ITEM: 'click_onboarding_faq_item'
  },
  CompanyInformation: {
    CLICK_USER_IDENTIFICATION_CONTINUE: 'click_onboarding_userIdentification_continue',
    CLICK_COMPANY_SELECTION_CONTINUE: 'click_onboarding_companySelection_continue',
    ENGAGEMENT_MET: 'effect_onboarding_landingPage_engagementMet'
  },
  EligibilityPolling: {
    CLICK_RETRY: 'click_onboarding_riskDecision_retry'
  },
  SoleTrader: {
    Details: {
      CLICK_MANUAL_ADDRESS_INPUT: 'click_onboarding_soleTraderDetails_manualAddressInput',
      CLICK_CONTINUE: 'click_onboarding_soleTraderDetails_continue'
    }
  },
  LimitedCompany: {
    DirectorSelection: {
      CLICK_CONTINUE: 'click_onboarding_directorSelection_continue'
    },
    DirectorSelected: {
      CLICK_COPY_DIRECTOR_LINK: 'click_onboarding_directorSelected_copyDirectorLink',
      CLICK_START: 'click_onboarding_directorSelected_start',
      CLICK_NAVIGATE_BACK_TO_MERCHANT: 'click_onboarding_directorSelected_navigateBackToMerchant',
      CLICK_CONTINUE: 'click_onboarding_directorSelected_continue'
    },
    DirectorStart: {
      CLICK_CONTINUE: 'click_onboarding_directorStart_continue'
    }
  },
  Checks: {
    SignedTerms: {
      CLICK_OPEN: 'click_onboarding_signedTerm_open',
      CLICK_CLOSE: 'click_onboarding_signedTerms_close',
      CLICK_CONFIRM: 'click_onboarding_signedTerms_confirm'
    }
  },
  OnboardingOutcomes: {
    Passed: {
      CLICK_NAVIGATE_BACK_TO_MERCHANT: 'click_onboarding_checksPassed_navigateBackToMerchant',
      CLICK_DIRECT_DEBIT_LINK: 'click_onboarding_checksPassed_directDebitLink'
    },
    Polling: {
      DECISION_DELAYED: 'effect_onboarding_checkOutcomeDecision_delayed'
    }
  },
  Experiments: {
    EXPOSE_COMPANY_SELECTION_EDUCATION: 'expose_onboarding_companySelection_kriyaEducation'
  },
  Shared: {
    ReturnToMerchant: {
      CLICK_CONFIRM: 'click_onboarding_returnToMerchant_confirm',
      CLICK_CANCEL: 'click_onboarding_returnToMerchant_cancel',
      CLICK_OPEN: 'click_onboarding_returnToMerchant_open'
    },
    PageLoading: {
      CLICK_NAVIGATE_BACK_TO_MERCHANT: 'click_onboarding_pageLoading_navigateBackToMerchant'
    }
  }
}
