import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'
import { OnboardingJourney } from '../../../../types/OnboardingJourney'
import TextInput from '../../../../components/TextInput'
import { emailPattern } from '../../../../utils/emailRegExp'
import captionBoxStyling from '../styling/captionBoxStyling'
import theme from '../../../../assets/theme'
import Typography from '@mui/material/Typography'
import { maxLength150 } from '../../../../utils/validationConstants'
import { UserIdentifierType } from '../../../../types/enums/UserIdentifierType'
import IdentifierSelection from './IdentifierSelection'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { defaultCountryCode } from '../../../../consts'
import ErrorText from '../../../error/FormErrors'
import { EventTypes } from '../../../../types/enums/TrackEventType'
import useTrackEvent from '../../../../custom-hooks/useTrackEvent'
import PanelWithDefaultButtons from '../../../../components/PanelWithDefaultButtons'
import { UserIdentifiers } from '../../../../types/UserIdentifiers'
import { GetUserIdentifiers, SetUserIdentifiers } from '../../../../utils/userInfoPopulation'
import { usePrePopulateOnboardingJourneyInfo } from '../../../../custom-hooks/usePrePopulateOnboardingJourney'

interface PrePopulateFormParams {
  data: OnboardingJourney
}

const PrePopulationForm = ({ data }: PrePopulateFormParams) => {
  const savedInformation = GetUserIdentifiers()
  const preselectedType =
    savedInformation?.phoneNumber !== undefined ? UserIdentifierType.PhoneNumber : UserIdentifierType.Email

  const prePopulateMutation = usePrePopulateOnboardingJourneyInfo({ onboardingJourneyId: data.id })
  const trackEvent = useTrackEvent()
  const form = useForm<UserIdentifiers>({
    mode: 'onChange',
    defaultValues: {
      email: savedInformation?.email,
      phoneNumber: savedInformation?.phoneNumber
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form

  const [userIdentifierType, setUserIdentifierType] = useState<UserIdentifierType>(preselectedType)

  const onSubmit = (formValues: UserIdentifiers) => {
    if (userIdentifierType === UserIdentifierType.Email) formValues.phoneNumber = undefined
    else formValues.email = undefined

    trackEvent(EventTypes.CompanyInformation.CLICK_USER_IDENTIFICATION_CONTINUE, {
      identificationType: userIdentifierType,
      identifier: (userIdentifierType == UserIdentifierType.Email ? formValues.email : formValues.phoneNumber) ?? ''
    })

    SetUserIdentifiers(formValues)

    prePopulateMutation.mutate(formValues)
  }

  return (
    <form id="pre-population-form" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ marginTop: theme.spacing(10), marginBottom: theme.spacing(22) }}>
        <IdentifierSelection identifierType={userIdentifierType} onIdentifierTypeChange={setUserIdentifierType} />
      </Box>

      <Box sx={captionBoxStyling}>
        {userIdentifierType === UserIdentifierType.Email ? (
          <TextInput
            label="Email"
            name="email"
            register={register}
            error={errors.email}
            validation={{
              required: 'Please enter your email address',
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email address'
              },
              maxLength: maxLength150
            }}
          />
        ) : (
          <Controller
            control={form.control}
            rules={{
              required: 'Please enter a valid mobile number',
              validate: (value) =>
                !value ||
                matchIsValidTel(value, { onlyCountries: [defaultCountryCode] }) ||
                'Please enter a valid mobile number'
            }}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <FormControl fullWidth>
                  <FormLabel htmlFor="phoneNumber">Mobile number</FormLabel>
                  <MuiTelInput
                    {...field}
                    sx={{ width: '100%' }}
                    data-cy="phoneNumber"
                    defaultCountry={defaultCountryCode}
                    onlyCountries={[defaultCountryCode]}
                    disableDropdown={true}
                    error={!!error}
                    id="phoneNumber"
                  />
                  {error && <ErrorText id={field.name}>{error.message}</ErrorText>}
                </FormControl>
              </Box>
            )}
            name="phoneNumber"
          />
        )}

        <div style={{ marginTop: theme.spacing(16) }}>
          <Typography variant="helperText2" sx={{ color: theme.palette.common.textGrey900 }}>
            By clicking "Continue", you agree to Kriya using your details to assess your eligibility for credit which
            may include soft checks at credit bureaus.
          </Typography>
        </div>

        <PanelWithDefaultButtons
          merchantName={data.merchant.name}
          redirectUrl={data.abandonedUrl}
          isLoading={prePopulateMutation.isLoading}
          isDisabled={!form.formState.isValid || prePopulateMutation.isLoading}
        />
      </Box>
    </form>
  )
}

export default PrePopulationForm
