import { retry } from '../api/apiBaseUrl'
import { useQuery } from 'react-query'
import { CheckType } from '../types/enums/CheckType'
import { apiClient } from '../api/apiClient'
import { SelfieCheckResponse } from '../types/checks/SelfieCheckResponse'

export const fetchCheckDetailsKey = 'fetch-check-details'

export const fetchCheckDetails = async (
  onboardingJourneyId: string,
  checkType: CheckType
): Promise<SelfieCheckResponse | {}> => {
  const response = await apiClient.get<SelfieCheckResponse | {}>(`/checks/${onboardingJourneyId}/${checkType}`)
  return response.data
}

export const useFetchCheckDetails = (onboardingJourneyId: string, checkType: CheckType) => {
  const { data, isLoading } = useQuery<SelfieCheckResponse | {}, Error>(
    [fetchCheckDetailsKey, checkType],
    () => fetchCheckDetails(onboardingJourneyId, checkType),
    { retry, refetchOnWindowFocus: false }
  )

  return { data, isLoading }
}
