import React from 'react'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'
import { OnboardingJourney } from '../../../types/OnboardingJourney'
import { Person } from '../../../types/Person'
import { Company } from '../../../types/Company'
import { CompanySearchComponent } from '../../../components/CompanySearchComponent'
import TextInput from '../../../components/TextInput'
import { emailPattern } from '../../../utils/emailRegExp'
import { useUpdateOnboardingJourney } from '../../../custom-hooks/useUpdateOnboardingJourney'
import captionBoxStyling from './styling/captionBoxStyling'
import { maxLength150 } from '../../../utils/validationConstants'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { defaultCountryCode } from '../../../consts'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ErrorText from '../../error/FormErrors'
import ReadOnlyField from '../../../components/ReadOnlyField'
import useTrackEvent from '../../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../../types/enums/TrackEventType'
import PanelWithDefaultButtons from '../../../components/PanelWithDefaultButtons'
import { useCompanyTypeContext } from '../CompanyTypeContext'

const invalidPhoneNumberError = 'Please enter a valid mobile phone number'

export interface LimitedCompanyFormParams {
  onboardingJourney: OnboardingJourney
}

export type CompanyInformationForm = {
  requester?: Person
  company?: Company
}

const LimitedCompanyForm = ({ onboardingJourney }: LimitedCompanyFormParams) => {
  const trackEvent = useTrackEvent()
  onboardingJourney = { ...onboardingJourney, flowType: undefined }

  const { company, requester } = onboardingJourney
  const { companyType } = useCompanyTypeContext()

  const form = useForm<CompanyInformationForm>({
    mode: 'onChange',
    defaultValues: {
      company: {
        name: company?.name || '',
        kriyaCompanyIdentifier: company?.kriyaCompanyIdentifier || ''
      },
      requester: {
        email: requester?.email || '',
        fullName: requester?.fullName || '',
        phoneNumber: requester?.phoneNumber || ''
      }
    }
  })

  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = form

  const onSubmit = (formValues: CompanyInformationForm) => {
    trackEvent(EventTypes.CompanyInformation.CLICK_COMPANY_SELECTION_CONTINUE, {
      companyType: companyType!
    })

    if (!isMutationLoading) {
      saveCompanyMutation({
        companyId: formValues.company?.kriyaCompanyIdentifier,
        requester: {
          email: formValues.requester?.email ?? '',
          fullName: formValues.requester?.fullName ?? '',
          phoneNumber: formValues.requester?.phoneNumber ?? ''
        }
      })
    }
  }

  const { mutate: saveCompanyMutation, isLoading: isMutationLoading } = useUpdateOnboardingJourney({
    onboardingJourneyId: onboardingJourney.id
  })

  const requiredFullNameError = 'Please enter your full name'

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="company-information-form">
      <Box sx={captionBoxStyling}>
        <CompanySearchComponent
          form={form}
          defaultCompanyValue={onboardingJourney.company?.name}
          companyType={companyType!}
        />

        <TextInput
          label="Your full name"
          name="requester.fullName"
          register={register}
          error={errors.requester?.fullName}
          validation={{
            required: requiredFullNameError,
            validate: (value: string) => value.trim().length > 0 || requiredFullNameError,
            maxLength: maxLength150
          }}
        />
        <TextInput
          label="Your email"
          name="requester.email"
          register={register}
          error={errors.requester?.email}
          validation={{
            required: 'Please enter your email address',
            pattern: {
              value: emailPattern,
              message: 'Please enter a valid email address'
            },
            maxLength: maxLength150
          }}
          readonly={!!onboardingJourney.requester?.email}
          getValues={getValues}
        />
        {requester?.phoneNumber ? (
          <ReadOnlyField name="requester.phoneNumber" label="Your mobile phone number" getValues={getValues} />
        ) : (
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => !value || matchIsValidTel(value, { onlyCountries: [defaultCountryCode] })
            }}
            render={({ field, fieldState }) => (
              <Box>
                <FormControl fullWidth>
                  <FormLabel htmlFor="requester.phoneNumber">Your mobile phone number</FormLabel>
                  <MuiTelInput
                    {...field}
                    sx={{ width: '100%' }}
                    data-cy="requester.phoneNumber"
                    defaultCountry={defaultCountryCode}
                    onlyCountries={[defaultCountryCode]}
                    disableDropdown={true}
                    error={fieldState.invalid}
                  />
                </FormControl>
                {fieldState.invalid && <ErrorText id={field.name}>{invalidPhoneNumberError}</ErrorText>}
              </Box>
            )}
            name="requester.phoneNumber"
          />
        )}

        <PanelWithDefaultButtons
          merchantName={onboardingJourney.merchant.name}
          redirectUrl={onboardingJourney.abandonedUrl}
          isLoading={isMutationLoading}
          isDisabled={!form.formState.isValid || isMutationLoading}
        />
      </Box>
    </form>
  )
}

export default LimitedCompanyForm
